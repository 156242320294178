// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getMessaging, getToken, onMessage } from "firebase/messaging";

const firebaseConfig = {
  apiKey: "AIzaSyCKGXspLpy4RSD7WnGm_QFZJy9aMDDOZCI",
  authDomain: "competeae-5a721.firebaseapp.com",
  projectId: "competeae-5a721",
  storageBucket: "competeae-5a721.appspot.com",
  messagingSenderId: "1060368741835",
  appId: "1:1060368741835:web:22a14609b9bf01e0cab24e",
  measurementId: "G-FZN0G0Y00R",
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const messaging = getMessaging(app);

export const getFCMToken = async () => {
  return getToken(messaging, {
    vapidKey:
      "BN36AEhsTUbr_VOgKPc_P-EcahgMrtkD10m2shT7JERwnA4tk0lJq8oNLlYuMia-RcXIpY5bj1MrKNsyV_pwfn8",
  })
    .then((currentToken) => {
      console.log(currentToken);
      const token = currentToken.toString();
      return token;
    })
    .catch((err) => {
      console.log("An error occurred while retrieving token. ", err);
    });
};

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
  });
