import Header from "./components/Header/Header";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Profile from "./pages/Profile/Profile";
import Register from "./pages/Register/Register";
import Login from "./pages/Login/Login";
import { RequireAuth, AuthProvider } from "react-auth-kit";
import ConfirmEmail from "./pages/ConfirmEmail/ConfirmEmail";
import Home from "./pages/Home/Home";
import ForgotPassword from "./pages/ForgotPassword/ForgotPassword";
import NewChallenge from "./pages/NewChallenge/NewChallenge.tsx";
import ConfirmDetails from "./pages/NewChallengeConfirmationDetails/ConfirmDetails.tsx";
import Competition from "./pages/Competition/Competition";
import NotFound from "./pages/NotFound/NotFound";
import Settings from "./pages/Settings/Settings";
import AcceptCompetition from "./pages/AcceptCompetition/AcceptCompetition";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import Competitions from "./pages/Competitions/Competitions";
import Teams from "./pages/Teams/Teams";
import Search from "./pages/Search/Search";
import Admin from "./pages/Admin/Admin";
import MyProgress from "./pages/Progress/Progress";
import SideBar from "./components/Side-bar-fixed/Side-bar-fixed";
import Privacy from "./pages/privacy/privacy.jsx";
import Carousel from "./components/caraousel-react/carousel-books";
import Navbar from "./components/global/navbar/Navbar.tsx";
import Dashboard from "./pages/Dashboard/Dashboard.tsx";
import { useEffect, useState } from "react";
import firebase, { getFCMToken, onMessageListener } from "./firebase";
import { getToken } from "firebase/messaging";
import { Toast } from "react-bootstrap";
import { categories } from "./stubData";
import { toast } from "react-toastify";
import OneSignal from "react-onesignal";

// import "./style/main.css";

function App() {
  const [show, setShow] = useState(false);
  const [notification, setNotification] = useState({ title: "", body: "" });

  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

  const types = ["You vs You", "Me vs You", "Us vs Them", "All 4 One"];

  useEffect(() => {
    OneSignal.init({
      appId: "d1df2b04-ab5d-44f1-8094-fe9e9c7b2ede",
    });
  }, []);

  return (
    <div className="App">
      <AuthProvider authType={"localstorage"} authName={"_auth"}>
        <BrowserRouter>
          {/* <Header /> */}
          <Navbar />

          <Routes>
            <Route path="*" element={<NotFound />} />
            <Route path="/register" element={<Register />} />
            <Route path="/privacy" element={<Privacy />} />
            <Route path="/login" element={<Login />} />
            <Route path="/confirm" element={<ConfirmEmail />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route
              path="/join/:code"
              element={
                <Elements stripe={stripePromise}>
                  <AcceptCompetition />
                </Elements>
              }
            ></Route>
            <Route
              path="/competitions"
              element={
                <RequireAuth loginPath={"/login"}>
                  <Competitions categories={categories} types={types} />
                </RequireAuth>
              }
            />
            <Route path="/search/:query" element={<Search />} />
            <Route path="/progress" element={<MyProgress />} />
            <Route
              path="/profile"
              element={
                <RequireAuth loginPath={"/login"}>
                  <Profile categories={categories} types={types} />
                </RequireAuth>
              }
            ></Route>
            <Route
              path="/settings"
              element={
                <RequireAuth loginPath={"/login"}>
                  <Settings />
                </RequireAuth>
              }
            ></Route>
            <Route
              path="/new-competition"
              element={
                <RequireAuth loginPath={"/login"}>
                  <NewChallenge categories={categories} types={types} />
                </RequireAuth>
              }
            ></Route>
            <Route
              path="confirm-details"
              element={
                <RequireAuth loginPath={"/login"}>
                  <Elements stripe={stripePromise}>
                    <ConfirmDetails />
                  </Elements>
                </RequireAuth>
              }
            ></Route>
            <Route
              path="dashboard"
              element={
                <RequireAuth loginPath={"/login"}>
                  <Dashboard />
                </RequireAuth>
              }
            ></Route>
            <Route
              path="/competition/:compId"
              element={
                <RequireAuth loginPath={"/login"}>
                  <Competition categories={categories} types={types} />
                </RequireAuth>
              }
            ></Route>
            <Route
              path="/teams"
              element={
                <RequireAuth loginPath={"/login"}>
                  <Teams />
                </RequireAuth>
              }
            ></Route>
            <Route
              path="/admin"
              element={
                <RequireAuth loginPath={"/login"}>
                  <Admin categories={categories} types={types} />
                </RequireAuth>
              }
            ></Route>
            <Route
              path="/"
              element={
                <RequireAuth loginPath={"/login"}>
                  <Home />
                </RequireAuth>
              }
            ></Route>

            <Route
              path="/side-bar"
              element={
                <RequireAuth loginPath={"/login"}>
                  <SideBar />
                </RequireAuth>
              }
            ></Route>

            <Route
              path="/caraousel-books"
              element={
                <RequireAuth loginPath={"/login"}>
                  <Carousel />
                </RequireAuth>
              }
            ></Route>
          </Routes>
        </BrowserRouter>
      </AuthProvider>
    </div>
  );
}

export default App;
