import React from "react";
import { useEffect, useState, useRef } from "react";
import { useAuthUser } from "react-auth-kit";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { faCirclePlus, faCircleXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ImageUploading from "react-images-uploading";
import Home from "./Home";
import { InfinitySpin } from "react-loader-spinner";
import Select from "react-select";
import { getActiveElement } from "@testing-library/user-event/dist/utils";
import Modal from "react-modal";
import Loader from "../../components/Loader/loader.jsx";

export default function CreatePost({ getHomePosts }) {
  const [loaderChecker, setLoaderChecker] = useState(false);
  const [posts, setPosts] = useState(null);
  const [users, setUsers] = useState([]);
  const [newPostText, setNewPostText] = useState("");
  const authUser = useAuthUser();
  const inputRef = useRef(null);
  const [postImage, setPostImage] = useState([]);
  const [postVideo, setPostVideo] = useState({});
  const [url, setUrl] = useState("");
  const [competitionTag, setCompetitionTag] = useState("");
  const [selectOptions, setSelectOptions] = useState([]);
  const selectInputRef = useRef(null);
  let percent = 0;
  const toastId = useRef(null);

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected ? "#725095" : "#725095",
      background: state.isSelected ? "#F0EFFF" : "white",
      ":hover": {
        background: "#F0EFFF",
      },
    }),
  };

  const customStylesModal = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      minWidth: "500px",
      border: "none !important",
      background: "none !important",
      overflow: "none !important",
    },
  };

  const colourStyles = {
    control: (styles) => ({ ...styles, backgroundColor: "white" }),
    option: (styles, { isDisabled }) => {
      return {
        ...styles,
        backgroundColor: isDisabled ? "red" : "green",
        color: "#FFF",
        cursor: isDisabled ? "not-allowed" : "default",
      };
    },
  };

  const options = [
    { value: 1, label: "Chocolate" },
    { value: 2, label: "Strawberry" },
    { value: 3, label: "Vanilla" },
  ];

  useEffect(() => {
    getAllCompetitions();
  }, []);

  const getAllCompetitions = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/challenges/all`)
      .then((res) => {
        setSelectOptions(res.data);
      })
      .catch((err) => console.log(err));
  };

  const makePost = () => {
    setLoaderChecker(true);
    console.log("postVideo", postVideo);

    console.log("Did Something");
    console.log("postImage", postImage);
    console.log("newPostText", newPostText.length);
    if (postImage.length > 0) {
      console.log("Api Call");
      let formData = new FormData();
      formData.append("image", postImage[0]);
      console.log("formData", formData);
      console.log("postImage", postImage[0]);
      axios
        .post(`${process.env.REACT_APP_API_URL}/upload`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          return axios.post(`${process.env.REACT_APP_API_URL}/posts`, {
            user: authUser()?.user_id,
            text: newPostText.length == 0 ? "" : newPostText,
            image: res.data.url,
            name: authUser().username,
            avatar: authUser().user_image,
            key:
              competitionTag?.value == undefined ? null : competitionTag.value,
            keyName:
              competitionTag?.label == undefined ? null : competitionTag.label,
          });
        })
        .then(() => {
          getHomePosts();
          setNewPostText("");
          setPostImage([]);
          setLoaderChecker(false);
          toast.success("New post! Make sure to share with your friends.");
        })
        .catch((err) => {
          console.log(err);
          setLoaderChecker(false);
        });
    }
    if (postVideo.size > 0) {
      let formData = new FormData();
      formData.append("image", postVideo);
      axios
        .post(`${process.env.REACT_APP_API_URL}/upload`, formData, config)
        .then((res) => {
          return axios.post(`${process.env.REACT_APP_API_URL}/posts`, {
            user: authUser()?.user_id,
            text: newPostText.length == 0 ? "" : newPostText,
            image: res.data.url,
            name: authUser().username,
            avatar: authUser().user_image,
            key:
              competitionTag?.value == undefined ? null : competitionTag.value,
            keyName:
              competitionTag?.label == undefined ? null : competitionTag.label,
          });
        })
        .then(() => {
          percent = 0;
          getHomePosts();
          setNewPostText("");
          setPostImage([]);
          setLoaderChecker(false);
          toast.success("New post! Make sure to share with your friends.");
        })
        .catch((err) => console.log(err));
    }
    if (postImage.length == 0 && postVideo?.name == undefined) {
      console.log("undefined");
      axios
        .post(`${process.env.REACT_APP_API_URL}/posts`, {
          user: authUser()?.user_id,
          text: newPostText.length == 0 ? "" : newPostText,
          name: authUser().username,
          avatar: authUser().user_image,
          key: competitionTag?.value == undefined ? null : competitionTag.value,
          keyName:
            competitionTag?.label == undefined ? null : competitionTag.label,
        })
        .then(() => {
          getHomePosts();
          setNewPostText("");
          setLoaderChecker(false);
          toast.success("New post! Make sure to share with your friends.");
        })
        .catch((err) => {
          console.log(err);
          setLoaderChecker(false);
        });
    }
    setPostImage([]);
    setPostVideo([]);
    setState({ file: "" });
    setCompetitionTag(null);
  };

  const makeVideoPost = () => {
    console.log("Did Something");
    if (newPostText !== "") {
      if (postImage.length > 0) {
        let formData = new FormData();
        formData.append("image", postImage[0]?.file);

        axios
          .post(`${process.env.REACT_APP_API_URL}/upload`, formData, config)
          .then((res) => {
            return axios.post(`${process.env.REACT_APP_API_URL}/posts`, {
              user: authUser()?.user_id,
              text: newPostText,
              image: res.data.url,
            });
          })
          .then(() => {
            getHomePosts();
            setNewPostText("");
            setPostImage([]);
            toast.success("New post! Make sure to share with your friends.");
          })
          .catch((err) => console.log(err));
      } else {
        axios
          .post(`${process.env.REACT_APP_API_URL}/posts`, {
            user: authUser()?.user_id,
            text: newPostText,
          })
          .then(() => {
            getHomePosts();
            setNewPostText("");
            toast.success("New post! Make sure to share with your friends.");
          })
          .catch((err) => console.log(err));
      }
    } else {
      toast.warn("Post can't be empty!");
    }
  };

  const [state, setState] = useState({ file: null });

  const handleFileChange = (event) => {
    console.log(event.target.files);
    if (
      [".jpg", ".jpeg", ".png", ".jfif", ".gif", ".tiff"].some((char) =>
        event?.target?.files[0]?.name.endsWith(char)
      )
    ) {
      setPostVideo([]);
      setPostImage(event.target.files);
      setState({
        file: URL.createObjectURL(event.target.files[0]),
      });
    } else {
      const selectedFile = event.target.files && event.target.files[0];
      console.log(event.target.files[0]);
      if (selectedFile) {
        setPostImage([]);

        setPostVideo(event.target.files[0]);
        const localURL = URL.createObjectURL(event.target.files[0]);
        setUrl(localURL);
      } else {
        return;
      }
    }
  };

  const config = {
    onUploadProgress: (progressEvent) => {
      const { loaded, total } = progressEvent;
      percent = loaded / total;
      if (toastId.current === null) {
        toastId.current = toast("Upload in Progess", { percent });
      } else {
        toast.update(toastId.current, { percent });
      }
    },
    headers: {
      "Content-Type": "multipart/form-data",
    },
  };

  const handleClick = () => {
    inputRef.current.click();
  };

  const handleImageChange = (event) => {
    setPostImage(event);

    setPostVideo({});
  };

  const handleSelectChange = (e) => {
    console.log("Change", e);
    setCompetitionTag(e);
  };

  return (
    <div className="bg-yellow-700 rounded-lg py-3 px-4 mb-5">
      <div className="flex gap-3 items-center ">
        <div className="w-12 h-12">
          <div className="w-12 h-12">
            <img className="w-12 h-12" src="/images/img.svg" alt="" />
          </div>
        </div>
        <input
          onChange={(e) => setNewPostText(e.target.value)}
          value={newPostText}
          className="bg-yellow-50 rounded-full w-full font-light
           placeholder:text-primary-700 text-gray-200 text-17xl py-4 px-6 border border-dark border-opacity-20 h-12 focus:outline-none"
          name=""
          id=""
          placeholder="What you have in your mind?"
        />
      </div>
      {postImage[0] && (
        <div className="mt-3 home__new-post-image-container">
          <img
            id="blah"
            src={state.file}
            className="home__new-post-image"
            alt="post-img"
          />
          <span
            className="home__new-post-image-remove"
            onClick={() => {
              setPostImage([]);
              setPostVideo({});
              setState({ file: URL.createObjectURL("") });
            }}
          >
            <FontAwesomeIcon icon={faCircleXmark} />
          </span>
        </div>
      )}

      {postVideo.size != undefined && (
        <div className="mt-3 home__new-post-image-container">
          <video
            className="home__new-post-image"
            source={state.file}
            alt="post-img"
          />
          <span
            className="home__new-post-image-remove"
            onClick={() => {
              setPostVideo({});
              setPostImage([]);
              setState({ file: URL.createObjectURL("") });
            }}
          >
            <FontAwesomeIcon icon={faCircleXmark} />
          </span>
        </div>
      )}
      <hr className="border-primary-700 border-b border-t border-opacity-20 my-3" />
      <div className="flex gap-4 justify-between items-center flex-wrap">
        <div className="d-flex">
          {/* <ImageUploading
            multiple={false}
            value={postImage}
            onChange={(e) => handleImageChange(e)}
            maxNumber={23432}
            dataURLKey="data_url"
          >
            {({ onImageUpload, dragProps }) => (
              <div
                className="home__new-post-upload"
                onClick={onImageUpload}
                {...dragProps}
              >
                <label
                  htmlFor="select-image"
                  className="flex px-5 py-3 bg-yellow-50 rounded-full cursor-pointer shadow-7xl gap-1"
                >
                  <span>
                    <img
                      className="w-4 h-4"
                      src="/images/picture-svgrepo-com 1.svg"
                      alt=""
                    />
                  </span>

                  <span className="font-semibold text-7xl text-primary-700">
                    Add Image
                  </span>
                </label>
              </div>
            )}
          </ImageUploading> */}
          <div onClick={handleClick}>
            <label
              htmlFor="select-image"
              className="flex px-5 py-3 bg-yellow-50 rounded-full cursor-pointer shadow-7xl gap-1"
            >
              <span>
                <img
                  className="w-4 h-4"
                  src="/images/picture-svgrepo-com 1.svg"
                  alt=""
                />
              </span>
              <span className="font-semibold text-7xl text-primary-700">
                Add Image/Video
              </span>
              <input
                style={{ display: "none" }}
                ref={inputRef}
                accept="video/*,image/*"
                name="Add Video"
                type="file"
                onChange={handleFileChange}
              />
            </label>
          </div>
        </div>

        <Select
          placeholder="Select Competition Tag"
          value={competitionTag}
          onChange={handleSelectChange}
          options={selectOptions}
          styles={customStyles}
        />

        <button
          onClick={makePost}
          type="button"
          className="flex items-center justify-center gap-1 bg-primary-700 text-yellow-50 text-7xl font-semibold rounded-full py-3 px-7 sm:px-14 hover:no-underline shadow-7xl"
        >
          Post
        </button>
      </div>

      <Modal
        isOpen={loaderChecker}
        onRequestClose={() => setLoaderChecker(false)}
        style={customStylesModal}
      >
        <div>
          <Loader />
        </div>
      </Modal>
    </div>
  );
}
