import Rocket from "./../../assets/images/rocket.png";
import AlexUser from "./../../assets/images/alexa.png";
import axios from "axios";

const AlertCard = ({ data }) => {
  console.log(data);

  const readNotifications = (id) => {
    axios
      .patch(
        `${process.env.REACT_APP_API_URL}/notification/updateNotificationStatus?notification_id=${id}&status=true`
      )
      .then((res) => {});
  };

  return (
    <div
      className="alert-card w-[490px]"
      onClick={() => {
        readNotifications(data._id);
      }}
    >
      <div className="flex justify-between items-center mr-1">
        <div className="flex items-center  ">
          <img src={AlexUser} alt="Alexa" />
          <h2 className="ml-3 font-medium text-[12px]">{data.name}</h2>
        </div>
        <h3 className="text-[12px] font-medium text-black">now</h3>
      </div>
      <div className="flex items-end justify-content-end  ">
        <div style={{ marginLeft: "10px" }} className="mr-4">
          <h4 className="font-medium text-[16px]">{data.message[0].title}</h4>
          <p className="font-normal text-[12px]">{data.message[0].body}</p>
        </div>
        <div>
          <img src={Rocket} alt="Rocket" />
        </div>
      </div>
    </div>
  );
};

export default AlertCard;
