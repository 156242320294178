import "./AddMeasurements.scss";
import Input from "../../../../components/Input/Input";
import Button from "../../../../components/Button/Button";
import axios from "axios";
import { useAuthUser } from "react-auth-kit";
import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import Select from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";

const AddMeasurements = ({
  challengeId,
  getSubmissions,
  placeholder_text,
  challengeData,
  setCheckChallenge,
}) => {
  const userData = useAuthUser();
  const [value, setValue] = useState("");
  const [startValue, setStartValue] = useState("");
  const [goalValue, setGoalValue] = useState("");
  const [participantCheck, setParticipantCheck] = useState(false);
  const [challenge, setChallenge] = useState(true);
  const authUser = useAuthUser();
  const [participants, setParticipants] = useState([]);
  const [maintainUser, setMaintainUser] = useState("");
  const [users, setUsers] = useState([]);
  const [externalUsers, setExternalUsers] = useState();
  const [externalInternalChecker, setExternalInternalChecker] = useState();
  const [externals, setExternals] = useState([]);

  console.log("challengeData", challengeData);

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API_URL}/users`).then((res) => {
      let array = [];
      for (let i = 0; i < res.data.length; i++) {
        array.push({
          label: res.data[i].user_name,
          value: res.data[i]._id,
        });
      }
      setUsers(array);
    });
  }, []);

  const handleExternalUsers = (event) => {
    setExternalUsers(event.target.value);
  };

  const onChangeValue = (event) => {
    console.log(event.target.value);
    setExternalInternalChecker(event.target.value);
  };

  const makeSubmissions = () => {
    axios
      .post(`${process.env.REACT_APP_API_URL}/submissions/`, {
        user: userData().user_id,
        challenge_id: challengeId,
        date: Date.now(),
        value: value,
      })
      .then((res) => {
        setValue("");
        getSubmissions();
      })
      .catch((err) => console.error(err));
  };

  const currentValue = () => {
    axios
      .patch(
        `${process.env.REACT_APP_API_URL}/challenges/${challengeId}/participantProgress`,
        {
          current: value,
          participant_id: authUser().user_id,
          team_id: null,
        }
      )
      .then((res) => {
        setGoalValue("");
        setStartValue("");
        setValue("");
        toast.success("Your current value is updated");
        setChallenge(!challenge);
        setCheckChallenge(challenge);
      })
      .catch((err) => console.error(err));
  };

  const goalStartValue = () => {
    axios
      .put(`${process.env.REACT_APP_API_URL}/challenges/${challengeId}`, {
        goal: goalValue,
        participants: {
          start_measurement: startValue,
        },
      })
      .then((res) => {
        setGoalValue("");
        setStartValue("");
        setChallenge(!challenge);
        setCheckChallenge(challenge);
      })
      .catch((err) => console.error(err));
  };

  const StartValue = () => {
    axios
      .patch(
        `${process.env.REACT_APP_API_URL}/challenges/${challengeId}/participantProgress`,
        {
          start_measurement: startValue,
          participant_id: authUser().user_id,
          team_id: null,
        }
      )
      .then((res) => {
        setGoalValue("");
        setStartValue("");
        toast.success("Your start value is updated");
        setChallenge(!challenge);
        setCheckChallenge(challenge);
      })
      .catch((err) => console.error(err));
  };

  const addParticipant = () => {
    axios
      .patch(
        `${process.env.REACT_APP_API_URL}/challenges/${challengeId}/add`,
        challengeData.type !== 2
          ? {
              start_measurement: startValue,
              participant_id: authUser().user_id,
              team_id: null,
            }
          : {
              team: true,
              participants: participants,
              externals: externals,
            }
      )
      .then((res) => {
        setGoalValue("");
        setStartValue("");
        toast.success("Team is Added, successfully");
        setParticipantCheck(true);
        setChallenge(!challenge);
        setCheckChallenge(challenge);
        setParticipants([]);
        setExternals([]);
      })
      .catch((err) => console.error(err));
  };

  const setParticipantAll = () => {
    console.log(maintainUser);

    if (externalInternalChecker == "system_user") {
      if (
        challengeData.participants.some(
          (elements) => elements.participant_id == maintainUser.value
        )
      ) {
        toast.warning(
          "Cannot Add this user, as he is already a part of this competition"
        );
        return;
      }

      if (maintainUser.value == authUser().user_id) {
        toast.warning("You Cannot add yourself");
      } else {
        let participant = {
          participant_id: maintainUser.value,
          user_name: maintainUser.label,
          team_id: authUser().user_id,
          consent: null,
          start_measurement: 0,
          date_joined: new Date(),
        };

        setParticipants([...participants, participant]);

        console.log("participants", participants);
      }
    } else {
      console.log("externalUsers", externalUsers);
      let external = {
        email_id: externalUsers,
      };
      setExternals([...externals, external]);
      console.log("externals", externals);
    }
  };

  const handleUserChange = (e) => {
    console.log("Change", e);
    setMaintainUser(e);
  };

  console.log("new Date()", new Date());
  console.log(
    "new Date(challengeData?.end_date)",
    new Date(challengeData?.end_date).getDate()
  );

  return (
    <div>
      <div
        style={{
          display:
            challengeData?.participants.some(
              (elements) => elements?.participant_id == authUser().user_id
            ) &&
            new Date(challengeData?.end_date)
              .toISOString()
              .replace(/T.*/, "")
              .split("-")
              .reverse()
              .join("-") >=
              new Date()
                .toISOString()
                .replace(/T.*/, "")
                .split("-")
                .reverse()
                .join("-")
              ? "block"
              : "none",
        }}
        className="add-measurements"
      >
        <div className="d-flex justify-content-between">
          <h3 className="add-measurements__title">Update Current Value</h3>
          <div className="add-measurements__form">
            <Input
              placeholder={`Enter Current Value`}
              type="number"
              value={value}
              onChange={(e) => setValue(e.target.value)}
            />
            <Button type="primary" text="Add" fn={() => currentValue()} />
          </div>
        </div>
      </div>

      <div
        style={{
          display:
            challengeData.type == 0 &&
            challengeData.forOther == true &&
            challengeData?.participants?.some(
              (elements) => elements?.participant_id == authUser().user_id
            )
              ? "block"
              : "none",
          marginTop: "2rem",
        }}
      >
        <div className="add-measurements">
          <div>
            <h3 className="add-measurements__title">Add your current value</h3>
          </div>
          <div className="add-measurements__form">
            <Input
              placeholder={`Enter Start Value`}
              type="number"
              value={startValue}
              onChange={(e) => setStartValue(e.target.value)}
            />

            <Button type="primary" text="Add" fn={() => StartValue()} />
          </div>
        </div>
      </div>

      <div
        style={{
          display:
            (challengeData?.type == 1 ||
              challengeData?.type == 3 ||
              challengeData?.type == 2) &&
            challengeData?.participants?.some(
              (elements) => elements?.participant_id == authUser().user_id
            ) &&
            new Date(challengeData?.end_date)
              .toISOString()
              .replace(/T.*/, "")
              .split("-")
              .reverse()
              .join("-") >=
              new Date()
                .toISOString()
                .replace(/T.*/, "")
                .split("-")
                .reverse()
                .join("-")
              ? "block"
              : "none",
          marginTop: "2rem",
        }}
      >
        <div className="add-measurements">
          <div>
            <h3 className="add-measurements__title">Update Start value</h3>
          </div>
          <div className="add-measurements__form">
            <Input
              placeholder={`Enter Start Value`}
              type="number"
              value={startValue}
              onChange={(e) => setStartValue(e.target.value)}
            />

            <Button type="primary" text="Add" fn={() => StartValue()} />
          </div>
        </div>
      </div>

      <div
        style={{
          display:
            challengeData.type == 2 &&
            challengeData?.participants?.some(
              (elements) => elements?.team_id == authUser().user_id
            ) &&
            challengeData.user !== authUser().user_id
              ? "block"
              : "none",
        }}
        className="bg-white mt-4 shadow-3xl px-8 sm:px-14 py-6 rounded-md"
      >
        <div className="d-flex flex-row justify-content-between">
          <h5 className="font-medium text-32xl mb-4">Enter User Detail</h5>

          <div className="mt-4 d-flex flex-row" onChange={onChangeValue}>
            <div style={{ marginRight: "1rem" }}>
              <input
                style={{ marginLeft: "50%" }}
                type="radio"
                value="system_user"
                name="gender"
              />{" "}
              <p>System Users</p>
            </div>

            <div>
              <input
                style={{ marginLeft: "50%" }}
                type="radio"
                value="external_user"
                name="gender"
              />
              <p>External Users</p>
            </div>
          </div>
          <button
            onClick={setParticipantAll}
            className="flex items-center mb-3 justify-center gap-1 text-black bg-yellow-700 text-24xl rounded-full py-3.5 px-14 hover:no-underline"
          >
            Add Participant
          </button>
        </div>

        <div
          style={{
            display:
              externalInternalChecker == "system_user" ? "block" : "none",
          }}
        >
          <Select
            onChange={handleUserChange}
            placeholder="Select User"
            options={users}
          />
        </div>

        <div
          style={{
            display:
              externalInternalChecker == "external_user" ? "block" : "none",
          }}
          className="flex flex-col gap-2"
        >
          <label htmlFor={"id1"} className="text-17xl font-medium">
            External Users
          </label>

          <div className="relative flex-grow">
            <input
              id={"id2"}
              value={externalUsers}
              type="text"
              onChange={handleExternalUsers}
              className="bg-yellow-100 border-primary-700 border border-opacity-25 text-primary-700 placeholder:text-primary-700 placeholder:text-opacity-40 rounded-full py-2 px-5 font-semibold text-5xl w-full"
              placeholder={"Enter Email"}
            />
          </div>
        </div>

        {participants.length > 0 ? (
          <div className="mt-3">
            <a className="mt-2">Selected users</a>
            {participants.map((item, index) => (
              <div className="d-flex flex-row justify-content-between">
                <p className="mt-3 text-15xl font-medium">
                  {index + 1} . {item.user_name}
                </p>
                <div
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    console.log("click");
                    let data = participants;
                    data.splice(index, 1);
                    console.log("data", data);
                    setParticipants([...data]);
                  }}
                  className="mt-3"
                >
                  <FontAwesomeIcon icon={faXmark} />
                </div>
              </div>
            ))}
          </div>
        ) : null}

        {externals.length > 0 ? (
          <div className="mt-3">
            <a className="mt-2">Selected users (External Users)</a>
            {externals.map((item, index) => (
              <div className="d-flex flex-row justify-content-between">
                <p className="mt-3 text-15xl font-medium">
                  {index + 1} . {item.email_id}
                </p>
                <div
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    console.log("click");
                    let data = externals;
                    data.splice(index, 1);
                    console.log("data", data);
                    setExternals([...data]);
                  }}
                  className="mt-3"
                >
                  <FontAwesomeIcon icon={faXmark} />
                </div>
              </div>
            ))}
          </div>
        ) : null}

        <div className="mt-3 d-flex flex-row justify-content-end">
          <button
            disabled={participants.length == 0}
            style={{ background: participants.length == 0 ? "grey" : "" }}
            onClick={addParticipant}
            className="flex mt-3 items-center mb-3 flex-end gap-1 text-black bg-yellow-700 text-24xl rounded-full py-3.5 px-14 hover:no-underline"
          >
            Create Team
          </button>
        </div>
        {/* End */}
      </div>

      {/* <div
        style={{
          display:
            challengeData.type == 3 &&
            !challengeData.participants.some(
              (elements) => elements.participant_id == authUser().user_id
            ) &&
            participantCheck == false
              ? "block"
              : "none",
          marginTop: "2rem",
        }}
      >
        <div className="add-measurements">
          <div>
            <h3 className="add-measurements__title">
              Enter Starting value to add as participant
            </h3>
          </div>
          <div className="add-measurements__form">
            <Input
              placeholder={`Enter Start Value`}
              type="number"
              value={startValue}
              onChange={(e) => setStartValue(e.target.value)}
            />

            <Button type="primary" text="Add" fn={() => addParticipant()} />
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default AddMeasurements;
