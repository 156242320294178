import React from "react";
import Iframe from "react-iframe";

export default function Privacy(e) {
  return (
    <Iframe
      url="https://privacy.competeae.com"
      width="100%"
      height="100%"
      display="initial"
      position="relative"
    />
  );
}
