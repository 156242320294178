import PageContent from "../../components/PageContent/PageContent";
import Sidebar from "../../components/Side-bar-fixed/Side-bar-fixed";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCirclePlus, faCircleXmark } from "@fortawesome/free-solid-svg-icons";
import Button from "../../components/Button/Button";
import axios from "axios";
import { useEffect, useState } from "react";
import { useAuthUser } from "react-auth-kit";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Post from "./Post/Post";
import ImageUploading from "react-images-uploading";
import { v4 as uuidv4 } from "uuid";
import "../../../src/style/main.css";
import Article from "./Article.jsx";
import WhoFollow from "./WhoFollow.jsx";
import Event from "./event/Event.tsx";
import Friends from "./friends/Friends.tsx";
import "./Home.scss";
import Loader from "../../components/Loader/loader.jsx";
import Modal from "react-modal";
import { useNavigate } from "react-router-dom";

import CreatePost from "./CreatePost.jsx";

const Home = () => {
  const [posts, setPosts] = useState(null);
  const [loaderChecker, setLoaderChecker] = useState(false);
  const [Follow, setFollow] = useState(null);
  const [users, setUsers] = useState([]);
  const [newPostText, setNewPostText] = useState("");
  const authUser = useAuthUser();
  const [postImage, setPostImage] = useState([]);
  const [DataForDisplay, setDataForDisplay] = useState([]);
  const [expanded, setExpanded] = useState(false);
  const [challengeData, setChallengeData] = useState(null);
  const [acceptInvitation, setAcceptInvitation] = useState(false);
  const [triggerChallenges, setTriggerChallenges] = useState(true);
  const navigate = useNavigate();

  const acceptRejectCompetition = (consent) => {
    console.log("consent", consent);

    axios
      .put(`${process.env.REACT_APP_API_URL}/challenges/updateConsent`, {
        challenge_id: challengeData._id,
        participant_id: authUser().user_id,
        consent: consent,
        type: challengeData.type,
      })
      .then((res) => {
        setAcceptInvitation(false);
        setTriggerChallenges(!triggerChallenges);
        if (consent == true) {
          navigate(`/competition/${challengeData._id}`);
        }
      })
      .catch((err) => console.error(err));
  };

  useEffect(() => {
    document.title = "Home";
  }, []);

  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/challenges/user/${authUser().user_id}`
      )
      .then((res) => {
        console.log("res", res.data);
        for (let i = 0; i <= res.data.length; i++) {
          console.log(res.data[0].participants);
          if (
            res.data[i].participants.find((data) => {
              return (
                data.participant_id == authUser().user_id &&
                data.consent == null &&
                res.data[i].consent == null &&
                res.data[i].user !== authUser().user_id
              );
            })
          ) {
            setChallengeData(res.data[i]);
            setAcceptInvitation(true);
          }
        }
        console.log("hi");
      })
      .catch((err) => console.error(err));
  }, [triggerChallenges]);

  useEffect(() => {
    setLoaderChecker(true);
    axios
      .get(`${process.env.REACT_APP_API_URL}/posts`)
      .then((res) => {
        setPosts(res.data);
        setDataForDisplay(res.data.slice(0, 5));
        setLoaderChecker(false);
      })
      .catch((err) => console.log(err));
  }, []);

  const getPosts = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/posts`)
      .then((res) => {
        setPosts(res.data);
        setDataForDisplay(res.data.slice(0, 5));
      })
      .catch((err) => console.log(err));
  };

  const getFollow = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/posts`)
      .then((res) => setPosts(res.data))
      .catch((err) => console.log(err));
  };

  const makePost = () => {
    if (newPostText !== "") {
      if (postImage.length > 0) {
        let formData = new FormData();
        formData.append("image", postImage[0].file);

        axios
          .post(`${process.env.REACT_APP_API_URL}/upload`, formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((res) => {
            return axios.post(`${process.env.REACT_APP_API_URL}/posts`, {
              user: authUser().user_id,
              text: newPostText,
              image: res.data.url,
            });
          })
          .then(() => {
            getPosts();
            setNewPostText("");
            setPostImage([]);
            toast.success("New post! Make sure to share with your friends.");
          })
          .catch((err) => console.log(err));
      } else {
        axios
          .post(`${process.env.REACT_APP_API_URL}/posts`, {
            user: authUser().user_id,
            text: newPostText,
          })
          .then(() => {
            getPosts();
            setNewPostText("");
            toast.success("New post! Make sure to share with your friends.");
          })
          .catch((err) => console.log(err));
      }
    } else {
      toast.warn("Post can't be empty!");
    }
  };

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      minWidth: "500px",
    },
  };

  return (
    <div>
      <div className="container">
        <div className="flex flex-col-reverse lg:!grid lg:grid-cols-3 xl:grid-cols-4 gap-6 mb-5">
          <div className="flex flex-col gap-6">
            <WhoFollow />
            <Event />
            {/* <div className="xl:hidden">
              <Friends />
            </div> */}
          </div>
          <div className="lg:col-span-2">
            <CreatePost getHomePosts={getPosts} />
            <div style={{ display: loaderChecker ? "block" : "none" }}>
              <Loader />
            </div>
            <div className="flex flex-col gap-5 mb-3">
              {DataForDisplay
                ? DataForDisplay.map((post) => {
                    return (
                      <Article
                        getHomePosts={getPosts}
                        data={post}
                        users={users}
                        toast={toast}
                        getPosts={getPosts}
                        authUser={authUser}
                        key={uuidv4()}
                      />
                    );
                  })
                : ""}
              <button
                style={{ display: !loaderChecker ? "block" : "none" }}
                type="button"
                onClick={() => {
                  setDataForDisplay(expanded ? posts.slice(0, 5) : posts);
                  setExpanded(!expanded);
                }}
              >
                {expanded ? "Show Less" : "Show More"}
              </button>
            </div>
          </div>
          <div className="hidden xl:block">
            <Friends />
          </div>
        </div>
      </div>

      <Modal
        isOpen={acceptInvitation}
        onRequestClose={() => setAcceptInvitation(acceptInvitation)}
        style={customStyles}
        contentLabel="Add Member"
      >
        <div className="add-member__modal">
          <div className="add-member__modal-intro">
            <h2>
              <b>You have been challenged for this competition?</b>
            </h2>
          </div>

          <ul>
            <li>Title:{challengeData?.name}</li>
            <li>
              Type:{" "}
              {challengeData?.type == 0
                ? "You vs You"
                : challengeData?.type == 1
                ? "Me vs You"
                : challengeData?.type == 2
                ? "Us vs them"
                : challengeData?.type == 3
                ? "1 for all"
                : null}
            </li>

            <li>
              Start Date:{" "}
              {challengeData?.start_date == undefined
                ? null
                : new Date(challengeData.start_date)
                    .toISOString()
                    .replace(/T.*/, "")
                    .split("-")
                    .reverse()
                    .join("-")}
            </li>
            <li>
              End Date:{" "}
              {challengeData?.end_date == undefined
                ? null
                : new Date(challengeData.end_date)
                    .toISOString()
                    .replace(/T.*/, "")
                    .split("-")
                    .reverse()
                    .join("-")}
            </li>
            <li>Charges: {challengeData?.wager}</li>
          </ul>

          <div class="d-flex flex-row justify-content-end mt-3">
            <button
              onClick={() => acceptRejectCompetition(false)}
              className=" mt-4 flex items-center justify-center gap-1 bg-primary-700 text-yellow-50 text-7xl font-semibold rounded-full py-3 px-7 sm:px-14 hover:no-underline shadow-7xl"
            >
              Reject
            </button>
            <button
              onClick={() => acceptRejectCompetition(true)}
              className="ml-3 mt-4 flex items-center justify-center gap-1 bg-primary-700 text-yellow-50 text-7xl font-semibold rounded-full py-3 px-7 sm:px-14 hover:no-underline shadow-7xl"
            >
              Accept
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default Home;
