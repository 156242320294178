import CompetitionReport from "./Components/CompetitionReport/CompetitionReport";
import PageContent from "../../components/PageContent/PageContent";
import Sidebar from "../../components/Side-bar-fixed/Side-bar-fixed";
import Summery from "./Components/Summery/Summery";
import RecentlyPlayed from "./Components/RecentlyPlayed/RecentlyPlayed";
import "./Profile.scss";
import RecentPosts from "./Components/RecentPosts/RecentPosts";
import { useState, useEffect } from "react";
import axios from "axios";
import { useAuthUser } from "react-auth-kit";
import { Route, useLocation } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Button from "../../components/Button/Button";

const Profile = ({ categories, types, route }) => {
  const authUser = useAuthUser();
  const location = useLocation();
  const [competitions, setCompetitions] = useState(null);
  const [posts, setPosts] = useState(null);

  // console.log("id", location.state.id);

  const followPost = (follow) => {
    axios
      .post(`${process.env.REACT_APP_API_URL}/follower/follow`, {
        subscriber_id: follow,
        user_id: authUser().user_id,
        followed: true,
      })
      .then((res) => {
        toast.success(`Successfully followed ${follow}`);
      })
      .catch((err) => console.error(err));
  };

  useEffect(() => {
    document.title = "Profile";
  }, []);

  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/challenges/user/${
          location?.state?.id == undefined
            ? authUser().user_id
            : location.state.id
        }`
      )
      .then((res) => setCompetitions(res.data))
      .catch((err) => console.error(err));

    axios
      .get(
        `${process.env.REACT_APP_API_URL}/posts/user/${
          location?.state?.id == undefined
            ? authUser().user_id
            : location.state.id
        }`
      )
      .then((res) => setPosts(res.data))
      .catch((err) => console.error(err));
  }, []);

  return (
    <section className="profile">
      {/* <Sidebar /> */}
      <PageContent>
        {competitions && posts ? (
          <>
            <div className="profile__intro d-flex justify-content-between">
              <div>
                <h2 className="profile__intro-title">
                  Hey,{" "}
                  {location?.state?.name == undefined
                    ? authUser().username
                    : location?.state?.name}
                  !
                </h2>
                <p className="profile__intro-welcome">
                  Welcome back, nice to see you again!
                </p>
              </div>
              <div>
                <button
                  onClick={() =>
                    followPost(
                      location?.state?.id == undefined
                        ? authUser().user_id
                        : location.state.id
                    )
                  }
                  className="button button--primary"
                >
                  Follow
                </button>
              </div>
            </div>
            <div className="profile__content">
              <Summery
                totalCompetitions={competitions.length}
                wonCompetitions={
                  competitions.filter(
                    (challenge) => challenge.winner === authUser().user_id
                  ).length
                }
              />
              <RecentlyPlayed competitions={competitions} toast={toast} />
              <CompetitionReport
                categories={categories}
                competitions={competitions}
                types={types}
                user_id={
                  location?.state?.id == undefined
                    ? authUser().user_id
                    : location?.state.id
                }
              />
              <RecentPosts posts={posts} authUser={authUser} />
            </div>
          </>
        ) : (
          ""
        )}
      </PageContent>
      <ToastContainer />
    </section>
  );
};

export default Profile;
