// @ts-ignore
import UserInfo from "../../../components/common/UserInfo.tsx";
// @ts-ignore
import Messenger from "./Messenger.tsx";
// @ts-ignore
import SearchPeople from "./SearchPeople.tsx";
import { useState, useEffect } from "react";
import axios from "axios";
import { useAuthUser } from "react-auth-kit";

export default function Friends() {
  const authUser = useAuthUser();
  const [users, setUsers] = useState([]);
  const [expanded, setExpanded] = useState(false);
  const [DataForDisplay, setDataForDisplay] = useState([]);

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API_URL}/users`).then((res) => {
      setUsers(res.data);
      setDataForDisplay(res.data.slice(0, 5));
    });
  }, []);

  return (
    <div className="mb-10">
      <div className="bg-yellow-700 bg-opacity-50 pt-3 pb-4 px-5 rounded-lg mb-4">
        <h4 className="text-28xl font-medium mb-4">Friends</h4>
        <SearchPeople />
        <div className="flex flex-col gap-4">
          {DataForDisplay.map((user) => (
            <div className="flex justify-between">
              <UserInfo
                image={user?.profile_pic}
                name={
                  user?.user_name == undefined ? user?.name : user?.user_name
                }
                active="Online 2 min"
              />
              <button type="button">
                <img
                  className="w-[18px] h-[18px]"
                  src="/images/icons8-send-60 3.png"
                  alt=""
                />
              </button>
            </div>
          ))}
          <button
            type="button"
            onClick={() => {
              setDataForDisplay(expanded ? users.slice(0, 5) : users);
              setExpanded(!expanded);
            }}
          >
            {expanded ? "Show Less" : "Show More"}
          </button>
        </div>
      </div>
      {/* <Messenger /> */}
    </div>
  );
}
