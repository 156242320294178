import React from "react";
import Loader from "react-js-loader";

const loader = () => {
  return (
    <div>
      <Loader
        className="bg-yellow-700"
        type="spinner-circle"
        bgColor={"rgb(114 80 149)"}
        size={100}
      />
    </div>
  );
};

export default loader;
