import Iframe from "react-iframe";
import { useEffect } from "react";

export default function MyProgress() {
  useEffect(() => {
    document.title = "My Progress";
  }, []);
  return (
    <Iframe
      url="http://35.83.209.174:9001"
      width="100%"
      height="100%"
      id="myId"
      className="myClassname"
      display="initial"
      position="relative"
    />
  );
}
